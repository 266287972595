body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'FocoRegular', 'FocoBlack', 'FocoBold', 
    'Ubuntu', 'Burbank', 'GuavaCandy', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(180deg, #C02A22 0%, #E57435 37.5%, #E57435 53.12%, #C42F24 79.69%, #C02A22 100%) !important;
}

html, body, #root{
  height: 100% !important;
}
